// --------------------------------------------------------------------------
// VARIABLES
//---------------------------------------------------------------------------

$mainBackgroundColor: #fff;
$secondaryBackgroundColor: #f1f5f9;
$thirdBackgroundColor: #dbe1e6;
$mainSectionTitle: #1e293b;
$navBarBackGround: #0f172a;
$navBarTextColor: #fefefe;

$mainButtonBgColor: #b81237;
$mainButtonTextColor: #fff;
$secondaryButtonBgColor: #00305e;
$secondaryButtonTextColor: #fff;
$hoverButtonBgColor: #0f172a;
$LabelColor: #3f4855;

/* variables couleurs */

$green: #3b641f;
$yellow: #f59e0b;
$darkRed: #7c0721;

//$mainButtonColor: ;
//$secondaryButtonColor: ;
//$hoverButtonColor: ;
$CatalogTextColor: #00305e;

// clone des variables kendo

//$baseColor: ???;
$errorColor: #7c0721;
//$infoColor: ???;
$primaryColor: #00305e;
//$secondaryColor: ???;
$successColor: #3b641f;
//$tertiaryColor: ???;
$warningColor: #f59e0b;
